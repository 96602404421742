<template>
  <div class="account-pages my-5 pt-sm-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="w-75">
          <div class="card">
            <div class="card-body">
              <div class="text-center">
                <div class="clearfix"></div>
                <div>
                  <img
                    src="@/assets/images/users/pngegg.png"
                    alt
                    class="avatar-lg rounded-circle img-thumbnail"
                  />
                </div>
                <h5 class="mt-3 mb-1">Actualizar Contraseña</h5>
              </div>
              <hr class="my-4" />
              <div class="text-muted">
                <form class="table-responsive mt-4 mb-0">
                  <div class="mt-4 mb-1">
                    <p class="mb-1">Nueva contraseña :</p>
                    <input
                      v-model="newPassword"
                      type="password"
                      name="password"
                      placeholder="Escriba su nueva contraseña"
                      class="form-control"
                    />
                  </div>
                  <div class="mt-4 mb-1">
                    <p class="mb-1">Confirmar contraseña :</p>
                    <input
                      v-model="confirmPassword"
                      type="password"
                      name="password"
                      placeholder="Confirme su contraseña"
                      class="form-control"
                    />

                    <div class="mt-4 mb-1 text-center">
                      <b-button
                        id="show-btn"
                        variant="primary"
                        @click="comparePassword"
                        >Actualizar contraseña</b-button
                      >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { getUserinfo } from '../../../helpers/userActions/userActions';
export default {
  name: 'Reset-password',
  data() {
    return {
      newPassword: null,
      confirmPassword: null,
    };
  },
  methods: {
    comparePassword() {
      if (this.newPassword === this.confirmPassword) {
        this.updatePassword();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Las contraseña deben coincidir!',
        });
      }
    },
    updatePassword() {
      const { id } = getUserinfo();
      this.$http
        .patch(`users/password/${id}`, {
          password: this.newPassword,
        })
        .then(({ data }) => {
          Swal.fire({
            icon: 'success',
            title: 'Trabajo realizado correctamente',
            text: data.msg,
            timer: 1200,
          });
          setTimeout(() => {
            return this.$router.push('/login');
          }, 1000);
        })
        .catch(({ data }) =>
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: data.msg,
          })
        );
    },
  },
};
</script>
